import { type ClientEnv } from '../../app/env.both';
import { getEnsure } from './ensure';

export type ElevenLabsConfig = ReturnType<typeof elevenLabs>;

export function elevenLabs(env: ClientEnv) {
  const ensure = getEnsure(env);
  return {
    defaultVoiceId: ensure(
      'ELEVEN_LABS_DEFAULT_VOICE_ID',
      'TtRFBnwQdH1k01vR0hMz'
    ),
  };
}
