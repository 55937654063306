import { useCallback, useEffect, useState } from 'react';

import { getAudioContext } from '../../../services/audio/audio-context';
import { getStaticAssetPath } from '../../../utils/assets';
import { releaseMediaStream } from '../../../utils/media';
import { playWithCatch } from '../../../utils/playWithCatch';
import { useHostClientId } from '../../Player';
import { useRTCService } from '../../WebRTC';

export function BGAudioButton(): JSX.Element | null {
  const stage = useRTCService('stage');
  const hostClientId = useHostClientId();

  const [bgMusicInfo, setBgMusicInfo] = useState<null | {
    audio: HTMLAudioElement;
    mesn: MediaElementAudioSourceNode;
    msdn: MediaStreamAudioDestinationNode;
  }>(null);

  const play = () => {
    const ctx = getAudioContext();

    const audio = new Audio();
    audio.src = getStaticAssetPath(
      'audios/test-audio-birocratic-bob-ross-goes-to-hollywood-v0.mp3'
    );
    playWithCatch(audio);

    const mesn = ctx.createMediaElementSource(audio);
    const msdn = ctx.createMediaStreamDestination();
    mesn.connect(msdn);

    stage.audioBus.setMusicTrack(msdn.stream.getAudioTracks()[0]);
    setBgMusicInfo({ audio, msdn, mesn });
  };

  const stop = useCallback(() => {
    if (!bgMusicInfo) return;
    const { audio, mesn, msdn } = bgMusicInfo;
    releaseMediaStream(msdn.stream);
    audio.pause();
    mesn.disconnect();
    msdn.disconnect();
    stage.audioBus.setMusicTrack(null);
    setBgMusicInfo(null);
  }, [bgMusicInfo, stage.audioBus]);

  const handleToggle = () => {
    if (!hostClientId) return;

    if (bgMusicInfo) {
      stop();
    } else {
      play();
    }
  };

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  return (
    <div>
      <button
        type='button'
        className={`${
          bgMusicInfo ? 'btn-delete' : 'btn-primary'
        } w-full px-10 h-7 text-sms`}
        onClick={handleToggle}
      >
        {bgMusicInfo ? 'Stop BG Test Music' : 'Broadcast BG Test Music'}
      </button>
      <div className='flex justify-between items-center mt-2'>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://birocratic.bandcamp.com/track/bob-ross-goes-to-hollywood-2'
          className='text-3xs text-icon-gray'
        >
          BG Test: Birocratic - bob ross goes to hollywood (CC BY-NC-ND 3.0)
        </a>
        <button
          type='button'
          className='btn-secondary text-3xs text-icon-gray p-1'
          disabled={!bgMusicInfo}
          onClick={() => {
            if (!bgMusicInfo) return;
            bgMusicInfo.audio.currentTime = bgMusicInfo.audio.currentTime + 10;
          }}
        >
          Seek +10s
        </button>
      </div>
    </div>
  );
}
