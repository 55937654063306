import { type RemoteStreamType } from 'agora-rtc-sdk-ng';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';

import { type IRTCService } from '../../../services/webrtc';
import { useGameHostingController } from '../../Game/GameHostingProvider';
import { useIsLiveGamePlay } from '../../Game/hooks';
import { useHostClientId } from '../../Player';
import { useIsStreamSessionAlive } from '../../Session';
import { useMyClientId } from '../../Venue/VenuePlaygroundProvider';
import { StreamStats, useRTCService } from '../../WebRTC';

export function HostStreamSwitchButton(): JSX.Element | null {
  const stageRTCService = useRTCService('stage');
  const hostClientId = useHostClientId();
  const myClientId = useMyClientId();
  const isSessionAlive = useIsStreamSessionAlive();
  const isHost = myClientId === hostClientId;

  const handleSwitchStreamQuality = async (type: RemoteStreamType) => {
    if (!hostClientId) return;
    try {
      await stageRTCService.setRemoteVideoStreamType(hostClientId, type);
    } catch (error) {
      alert(`set quality failed, ${error}`);
    }
  };
  return (
    <div className='flex'>
      <button
        type='button'
        className='btn-primary w-1/2 mr-1 h-7 text-sms'
        disabled={!isSessionAlive || isHost}
        onClick={() => handleSwitchStreamQuality(0)}
      >
        High Quality
      </button>
      <button
        type='button'
        className='btn-warning w-1/2 ml-1 h-7 text-sms'
        disabled={!isSessionAlive || isHost}
        onClick={() => handleSwitchStreamQuality(1)}
      >
        Low Quality
      </button>
    </div>
  );
}

function HostStreamStats(props: {
  name: string;
  rtcService: IRTCService;
}): JSX.Element | null {
  const clientId = useGameHostingController()?.clientId;
  const [showStats, setShowStats] = useState(false);

  const handleToggle = () => {
    setShowStats(!showStats);
  };

  if (!clientId) return null;

  return (
    <div className='my-2'>
      <button
        type='button'
        className={`${
          showStats ? 'btn-delete' : 'btn-primary'
        } w-full px-10 h-7 text-sms mb-1`}
        onClick={handleToggle}
      >
        {showStats ? 'Close Stats' : 'Show Stats'} ({capitalize(props.name)})
      </button>
      {showStats && (
        <StreamStats
          id='host-stream-stats'
          rtcService={props.rtcService}
          uid={clientId}
        />
      )}
    </div>
  );
}

export function HostStreamStatsWrapper(): JSX.Element | null {
  const stageRTCService = useRTCService('stage');
  const ondRTCService = useRTCService('ond');
  const gameRTCService = useRTCService('game');
  const isLiveGame = useIsLiveGamePlay();

  return (
    <div>
      <HostStreamStats
        name='stage'
        rtcService={isLiveGame ? stageRTCService : ondRTCService}
      />
      <HostStreamStats name='game' rtcService={gameRTCService} />
    </div>
  );
}
