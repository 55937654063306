import copy from 'copy-to-clipboard';
import { useCallback, useEffect, useRef, useState } from 'react';

import { loadImageAsPromise } from '../../../utils/media';
import { useInfrequentAnimationFrame } from '../../CrowdFrames/useInfrequentAnimationFrame';
import { CopyIcon } from '../../icons/CopyIcon';
import { useSentimentContext } from '../../Sentiment/Context';
import { type Preview, profileFor } from '../../Sentiment/type';
import { useIsStreamSessionAlive, useStreamSessionId } from '../../Session';
import { useVenueId } from '../../Venue/VenueProvider';

function Render(props: { preview: Preview }): JSX.Element {
  const { preview } = props;
  const profile = profileFor(preview.profileIndex);
  const cvs = useRef<HTMLCanvasElement>(null);
  const ctx = cvs.current?.getContext('2d');
  const frameIdx = useRef(0);
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    async function init() {
      setImage(await loadImageAsPromise(preview.data));
    }
    init();
  }, [preview.data]);

  useInfrequentAnimationFrame(
    useCallback(() => {
      if (!ctx || !image) return;
      ctx.drawImage(
        image,
        0,
        frameIdx.current * profile.height,
        profile.width,
        profile.height,
        0,
        0,
        profile.width,
        profile.height
      );
      if (frameIdx.current < profile.maxStrips) {
        frameIdx.current++;
      }
    }, [ctx, image, profile]),
    profile.delayMs,
    !image || frameIdx.current === profile.maxStrips
  );

  return <canvas ref={cvs} width={profile.width} height={profile.height} />;
}

export function SentimentPreview(): JSX.Element {
  const [showPreview, setShowPreview] = useState(false);
  const { preview } = useSentimentContext();

  const handleToggle = () => {
    setShowPreview(!showPreview);
  };

  return (
    <div className='w-full'>
      {showPreview && (
        <div className='relative w-full'>
          <div className='aspect-w-4 aspect-h-3 mb-3'>
            {preview && <Render preview={preview} />}
          </div>
          <div className='absolute bottom-2 w-full text-center text-white text-2xs filter drop-shadow-lp-upload-replace'>
            {preview
              ? `${preview.expression}(${preview.score.toFixed(2)})`
              : 'not detected'}
          </div>
        </div>
      )}
      <button
        type='button'
        className={`${
          showPreview ? 'btn-delete' : 'btn-primary'
        } w-full px-10 h-7 text-sms`}
        onClick={handleToggle}
      >
        {showPreview ? 'Close Preview' : 'Show Preview'}
      </button>
    </div>
  );
}

export function CopyStreamIdButton(): JSX.Element {
  const venueId = useVenueId();
  const sessionId = useStreamSessionId();
  const isSessionAlive = useIsStreamSessionAlive();

  const handleCopy = () => {
    if (!sessionId) {
      alert('No stream detected');
      return;
    }
    copy(`${venueId}/sessions/${sessionId}`);
  };

  return (
    <button
      type='button'
      className='btn-primary w-full px-10 h-7 text-sms flex items-center justify-center'
      onClick={handleCopy}
      disabled={!isSessionAlive}
    >
      <CopyIcon className='w-4 h-4' />
      <div className='ml-2'>
        {isSessionAlive ? 'Copy Stream ID' : 'No Stream'}
      </div>
    </button>
  );
}
